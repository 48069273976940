<template>
    <div class="body">
        <div class="container">
            <div class="list">
                <div class="tag-item tag-header">
                    <div class="tag-name">称号</div>
                    <div class="tag-sumury">学分</div>
                </div>
                <div class="tag-item" v-for="(item, index) in tagList" :key="index">
                    <div class="tag-name">
                        {{item.name}}
                    </div>
                    <div class="tag-sumury">
                        {{getRange(item)}}
                    </div>
                </div>
            </div>
            <div class="tip">
                学分可以通过学习课程、完成学习任务、点赞、评论等赚取。
            </div>
        </div>
    </div>
</template>

<script>
    // import { mapState } from 'vuex';
    import { getTagList} from '../api/task.js';
    import { checkLogin } from '../utils/common.js';
    export default {
        data() {
          return {
            tagList: [],  
          }  
        },
        mounted() {
            checkLogin().then(()=> {
                this.getTagList();
            })
        },
        methods:{
            getTagList() {
               getTagList().then((ret)=> {
                  this.tagList = ret.data; 
               }) 
            },
            getRange(item){
                var lt = item.lt;
                var gt = item.gt || '0';
                if(lt){
                    return `${gt} - ${lt}`;
                }
                return `${gt}以上`;
            }
        },
    }
</script>

<style scoped>
    @import url('../assets/css/common.css');
    .list{
        font-size: 16px;
        margin-top: 10px;
    }
    .tag-item{
        display: flex;
        justify-content: space-around;
        line-height: 1.8;
        align-items: center;
        /* border-bottom: 1px dashed #eee; */
    }
    .tag-header .tag-name, .tag-header .tag-sumury{
        font-weight: bold;
        font-size: 18px;
        color: #333;
    }
    .tag-name{
        font-size: 18px;
        /* font-weight: bold; */
    }
    .tag-sumury{
        font-size: 16px;
        width: 150px;
        color: #666;
        /* padding-left: 10px; */
        
    }
    .tip{
        box-sizing: border-box;
        border: 1px solid #e2e2e2;
        background-color: #f2f2f2;
        padding: 10px;
        margin-top: 3rem;
        border-radius: 2px;
        color: #999;
        font-size: 16px;
    }
</style>